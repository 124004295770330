import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { themeStyles, colors, presets } from '../utils/theme'
import { rhythm } from '../utils/typography'
import { GatsbyImage } from 'gatsby-plugin-image'
import { get } from 'lodash'
import Youtube from './Youtube'

const styles = {
  testimonies: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  testimoniesRow: {
    display: 'flex',
    justifyContent: 'center',
    margin: rhythm(-0.5),
    [presets.Tablet]: {
      margin: rhythm(-0.75),
    },
  },
  testimonyRowItem: {
    margin: rhythm(0.5),
    [presets.Tablet]: {
      margin: rhythm(0.75),
    },
  },
  testimonyItem: {
    cursor: 'pointer',
  },
  testimonyPicture: {
    width: '100px',
    height: '100px',
    [presets.Tablet]: {
      width: '128px',
      height: '128px',
    },
    //
  },
  selectedTestimonyHolder: {
    width: '100%',
    maxWidth: '700px',
    marginTop: rhythm(1),
    borderRadius: rhythm(0.5),
    backgroundColor: colors.blueGreen,
    border: `${rhythm(0.5)} solid ${colors.blueGreen}`,
  },
  selectedTestimonyVideo: {
  },
}

const Testimony = ({ name, picture, videoUrl, outerCss, ...props }) => {
  return (
    <div css={[styles.testimonyItem, outerCss]} {...props}>
      <GatsbyImage
        css={styles.testimonyPicture}
        image={get(picture, 'childImageSharp.gatsbyImageData')}
        alt={`Testimony Picture ${name}`}
      />
    </div>
  )
}

const TestimonyVideo = ({ url }) => {
  const ref = useRef()
  useEffect(() => {
    ref.current.scrollIntoView({ block: 'center' })
  }, [])
  return (
    <div ref={ref} css={styles.selectedTestimonyHolder}>
      <Youtube
        url={url}
        outerCss={styles.selectedTestimonyVideo}
      />
    </div>
  )
}

const TechProductTestimonies = ({ testimonies }) => {
  const [selectedTestimony, setSelectedTestimony] = useState(null)
  return (
    <div css={styles.testimonies}>
      <div css={styles.testimoniesRow}>
        {testimonies.map((t, idx) => (
          <Testimony
            key={idx}
            outerCss={styles.testimonyRowItem}
            {...t}
            onClick={() => setSelectedTestimony(t)}
          />
        ))}
      </div>
      {selectedTestimony && (
        <TestimonyVideo url={selectedTestimony.videoUrl} />
      )}
    </div>
  )
}

TechProductTestimonies.propTypes = {
  testimonies: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.object.isRequired,
      videoUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default TechProductTestimonies
