import React from 'react'
import Button from '../components/Button'
import { themeStyles, colors, presets } from '../utils/theme'
import { GatsbyImage } from "gatsby-plugin-image";
import LoanFlowLogo from '../img/loanflow/loanflow_logo.svg'
import { rhythm } from '../utils/typography';

const styles = {
  block: {
    display: 'flex',
    padding: `${rhythm(2.5)} ${rhythm(1.5)}`,
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  titleHolder: {
    display: 'flex',
    paddingBottom: 0,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    flexBasis: 'unset',
    marginBottom: rhythm(2),
    [presets.Tablet]: {
      alignItems: 'flex-start',
      textAlign: 'start',
      maxWidth: '400px',
      marginBottom: 0,
    }
  },
  logo: {
    width: '240px',
    marginBottom: rhythm(1),
  },
  title: {
    fontSize: rhythm(1.75),
    fontWeight: 300,
    marginBottom: rhythm(1),
    padding: 0,
  },
  featuredOuter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blueGreen,
    borderRadius: '16px',
    padding: `${rhythm(0.75)} ${rhythm(1)} ${rhythm(1)}`,
    [presets.Tablet]: {
      padding: `${rhythm(1)} ${rhythm(2)} ${rhythm(1.5)}`,
    },
  },
  featuredHolder: {
    textAlign: 'center',
  },
  featuredHeader: {
    display: 'block',
    color: colors.white,
    fontWeight: 600,
    fontSize: rhythm(0.7),
    marginBottom: rhythm(0.75),
  },
  image: {
    // width: '100%',
    margin: 0,
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
}

const logos = {
    'loanflow': LoanFlowLogo,
}

const TechProductPageTitle = ({ logo, title, actionText, actionUrl, featuredImageHeader, featuredImage }) => {
  return (
    <div css={themeStyles.contentWidth}>
      <div css={styles.block}>
        <div css={styles.titleHolder}>
          <img css={styles.logo} src={logos[logo]} alt='LoanFlow'/>
          <p css={styles.title}>
            {title}
          </p>
          <div css={styles.buttonContainer}>
            <Button
              href={actionUrl}
              text={actionText}
              analyticsTargetName={`Tech Product Page Hero ${actionText}`}
            />
          </div>
        </div>
        <div css={styles.featuredOuter}>
          <div css={styles.featuredHolder}>
            <label css={styles.featuredHeader} htmlFor='featured-image'>{featuredImageHeader}</label>
            <div css={styles.imageHolder}>
              <GatsbyImage id='featured-image' image={featuredImage} alt='Featured Image' css={[styles.image]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechProductPageTitle
