import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import TechProductPageTitle from '../components/TechProductPageTitle'
import TechProductBenefits from '../components/TechProductBenefits'
import Youtube from '../components/Youtube'

import { colors, themeStyles, presets } from '../utils/theme'
import { rhythm } from '../utils/typography'
import TechProductTestimonies from '../components/TechProductTestimonies'

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${themeStyles.elementMargin.md} 0`,
    ...themeStyles.contentWidth,
    maxWidth: '800px',
    ...themeStyles.textPadding,
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  arrow: {
    maxHeight: '170px',
  },
  showcase: {
    ...themeStyles.contentWidth,
    padding: `${rhythm(1)} ${rhythm(1)} ${rhythm(2)}`,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  showcaseHeader: {
    fontWeight: 400,
    fontSize: '30px',
  },
  showcaseVideoHolder: {
    backgroundColor: colors.blueGreen,
    borderRadius: rhythm(1),
    width: '100%',
    maxWidth: '700px',
    padding: `${rhythm(1)} ${rhythm(1.5)}`,
    [presets.Tablet]: {
      padding: `${rhythm(1.75)} ${rhythm(3)}`,
    },
  },
  testimonies: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: `${rhythm(1)} ${rhythm(1)} ${rhythm(3)}`,
  },
  testimoniesHeader: {
    fontSize: '22px',
    fontWeight: 600,
    color: colors.darkGreen,
  }
}

export const TechProductPageTemplate = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const {
    productLogoKey,
    showHeaderLinks,
    title,
    metadataTitle,
    metadataDescription,
    featuredImageHeader,
    featuredImage,
    targetUrl,
    buttonText,
    productBenefits,
    showcaseHeader,
    showcaseVideoUrl,
    testimoniesHeader,
    testimonies,
  } = frontmatter
  return (
    <Layout showLinks={showHeaderLinks} metadataTitle={metadataTitle} metadataDescription={metadataDescription}>
      <TechProductPageTitle
        logo={productLogoKey}
        title={title}
        featuredImageHeader={featuredImageHeader}
        featuredImage={get(featuredImage, 'childImageSharp.gatsbyImageData')}
        actionText={buttonText}
        actionUrl={targetUrl}
      />
      <TechProductBenefits
        outerCss={themeStyles.contentWidth}
        benefits={productBenefits}
      />
      <div css={styles.showcase}>
        <h2 css={styles.showcaseHeader}>{showcaseHeader}</h2>
        <div css={styles.showcaseVideoHolder}>
          <Youtube url={showcaseVideoUrl} />
        </div>
      </div>
      <div css={styles.testimonies}>
        <h2 css={styles.testimoniesHeader}>{testimoniesHeader}</h2>
        <TechProductTestimonies
          testimonies={testimonies}
          outerCss={themeStyles.contentWidth}
        />
      </div>
    </Layout>
  )
}

TechProductPageTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        productLogoKey: PropTypes.string,
        title: PropTypes.string,
        metadataTitle: PropTypes.string,
        metadataDescription: PropTypes.string,
        showHeaderLinks: PropTypes.bool,
        featuredImageHeader: PropTypes.string,
        featuredImage: PropTypes.object,
        targetUrl: PropTypes.string,
        buttonText: PropTypes.string,
        productBenefits: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            subText: PropTypes.string,
          }),
        ),
        showcaseHeader: PropTypes.string,
        showcaseVideoUrl: PropTypes.string,
        testimoniesHeader: PropTypes.string,
        testimonies: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            picture: PropTypes.object,
            videoUrl: PropTypes.string,
          }),
        ),
      })
    })
  }),
}

export default TechProductPageTemplate

export const pageQuery = graphql`
  query TechProductPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        productLogoKey
        showHeaderLinks
        title
        metadataTitle
        metadataDescription
        featuredImageHeader
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 609, quality: 100, layout: CONSTRAINED)
          }
        }
        targetUrl
        buttonText
        productBenefits {
          text
          subText
        }
        showcaseHeader
        showcaseVideoUrl
        testimoniesHeader
        testimonies {
          name
          picture {
            childImageSharp {
              gatsbyImageData(width: 128, quality: 100, layout: CONSTRAINED)
            }
          }
          videoUrl
        }
      }
    }
  }
`
